import './Projects.scss';
import ProjectContainer from "../../components/ProjectContainer/ProjectContainer";

const Projects = () => {

    return (
        <div className='container project-page'>
            <div className="text-zone">
                <h1>
                    Mes réalisations
                </h1>
            </div>
            <ProjectContainer />
        </div>
    )
}

export default Projects