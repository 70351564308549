import {Link, NavLink} from "react-router-dom";
import './Navbar.scss';
import LogoSubtitle from '../../assets/images/logo.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faFolderOpen, faHome} from "@fortawesome/free-solid-svg-icons";


const Navbar = () => {
    return  <div className='nav-bar'>
                <div className='logo'>
                    <Link to='/'>
                        <img className='sub-logo' src={LogoSubtitle} alt="Logo bds-dev"/>
                    </Link>
                </div>
                <div className='nav'>
                    <nav>
                        <NavLink to='/' exact='true' activeclassname='active'>
                            <FontAwesomeIcon icon={faHome} color='#80889B' />
                        </NavLink>
                        <NavLink to='/projects' exact='true' className='projects-link' activeclassname='active'>
                            <FontAwesomeIcon icon={faFolderOpen} color='#80889B' />
                        </NavLink>
                        <NavLink to='/contact' exact='true' className='contact-link' activeclassname='active'>
                            <FontAwesomeIcon icon={faEnvelope} color='#80889B' />
                        </NavLink>
                    </nav>
                </div>
            </div>
}

export default Navbar