import './Home.scss'

import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCss3, faDocker, faGitAlt, faJsSquare, faLaravel, faReact} from "@fortawesome/free-brands-svg-icons";


const Home = () => {

    const countYear = new Date().getFullYear() - 2021;

    return (
        <div className='container home-page'>
            <div className="text-zone">
                <h1>
                    Je suis Bastien, Fullstack développeur / PHP, C#, Javascript
                </h1>
                <h2><strong>Passionné</strong> depuis toujours par l'informatique, j'ai décidé de faire de ma passion un métier.</h2>
                <p>
                    Cela fait maintenant {countYear} ans que j'accompagne des sociétés pour concevoir et développer leurs idées.
                </p>
                <p>
                    Je suis de nature très curieuse, capable de m'adapter rapidement à de nouvelles
                    technologies et de nouveaux environnements.
                    Je parle <strong>couramment anglais</strong>, ce qui me permet de travailler avec des équipes internationales et de
                    comprendre facilement la documentation technique.
                </p>
                <p>
                    Je maîtrise différentes technologies <strong>front end</strong> (VueJS, React, JavaScript natif, Tailwind, Sass)
                    ainsi que plusieurs technologies <strong>back end</strong> (PHP, Laravel, ASP.net, Blazor)
                    afin de monter des applications complètes.
                </p>
                <p>
                    Afin de rendre vos applications <strong>scalables</strong> et le plus facilement <strong>maintenables</strong>,
                    je développe en utilisant différents concepts de la clean architecture (tests unitaires, tests fonctionnelles).
                    Je continue d'apprendre afin de maîtriser pleinement plusieurs concepts de développement informatique (DRY, SOLID ...).
                </p>
                <p>
                    Si je peux vous aider n'hésitez pas à me contacter.
                </p>
                <div className="link">
                    <Link to='/contact' className='flat-button'>Contactez moi !</Link>
                </div>
            </div>
            <div className={"cube-container"}>
                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faJsSquare} color='#EFD81D' />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faReact} color='#00DCFF' />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faCss3} color='#28A4D9' />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faDocker} color='#119AD4' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faLaravel} color='#FF291A' />
                        </div>
                        <div className='face6'>
                            <FontAwesomeIcon icon={faGitAlt} color='#EC4D28' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home