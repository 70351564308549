import './Layout.scss';
import Navbar from "../Navbar/Navbar";
import {Outlet} from "react-router-dom";
import Footer from "../Footer/Footer";

const Layout = () => {
    return  <div className='App'>
                <div className="page">
                    <header>
                        <Navbar />
                    </header>

                    <Outlet />
                    <Footer />
                </div>
            </div>
}

export default Layout