import './App.scss';
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Projects from "./pages/Projects/Projects";

function App() {
  return (
    <Routes>
        <Route path="/" element={< Layout />}>
          <Route index element={<Home />}/>
        </Route>
        <Route path="/contact" element={< Layout />}>
            <Route index element={<Contact />}/>
        </Route>
        <Route path="/projects" element={< Layout />}>
            <Route index element={<Projects />}/>
        </Route>
    </Routes>
  );
}

export default App;
