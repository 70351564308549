import './Footer.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <footer>
            <div className='links'>
                <div>
                    <a href="https://www.linkedin.com/in/bastien-da-silva-412764213/" rel="noreferrer" target='_blank'>
                        <FontAwesomeIcon icon={faLinkedin}/>
                    </a>
                </div>
                <div>
                    <a href="https://github.com/B45T13N" rel="noreferrer" target='_blank'>
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer