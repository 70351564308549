import './ProjectContainer.scss'
import {useEffect, useState} from "react";
import ProjectCard from "../ProjectCard/ProjectCard";

const ProjectContainer = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('/project_datas/project_datas.json')
            .then((response) => response.json())
            .then((data) => setProjects(data))
            .catch((error) => console.error('Erreur lors du chargement des projets:', error));
    }, []);

  return (
      <div className="project-container">
              {projects.map((project) => (
                  <ProjectCard
                      key={project.id}
                      title={project.title}
                      description={project.description}
                      link={project.link}
                      photoLink={project.samplePhotoLink} />
              ))}
      </div>
  )
}

export default ProjectContainer