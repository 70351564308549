import './ProjectCard.scss'
import {Link} from "react-router-dom";

const ProjectCard = ({title, description, photoLink, link}) => {

    return (
        <div className={`project-card`}>
            <div className={"img"}>
                <img src={photoLink} alt={`Image du site ${title}`} height={400} width={600}/>
            </div>
            <div className={"text"}>
                <h2>{title}</h2>
                <div className={"details"}>
                    <h3>Missions :</h3>
                    <ul>
                        {description.map((detail) =>
                            <li className={"project-details"}>{detail}</li>
                        )}
                    </ul>
                    <Link className={`project-card-link`} to={link} about={`Lien vers le site ${title}`}>Voir le site</Link>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard