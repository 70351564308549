import './Contact.scss'
import {useRef} from "react";
import emailJs from '@emailjs/browser'

const Contact = () => {
    const refForm = useRef();

    const sendEmail = (e) =>{
        e.preventDefault();

        emailJs.sendForm(
            process.env.REACT_APP_MAIL_JS_SERVICE_ID,
            process.env.REACT_APP_MAIL_JS_TEMPLATE_ID,
            refForm.current,
            process.env.REACT_APP_MAIL_JS_API_KEY_ID
        ).then(
            () => {
                alert("Votre message a bien été envoyé");
                window.location.reload(false)
            },
            (error) => {
                alert("Votre message n'a pas été envoyé");
                console.log('FAILED...', error);
            }
        )
    }

    return (
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    Contactez-moi
                </h1>
                <p>
                    Je peux vous aider à développer un site internet, ou ajouter des nouvelles fonctionnalités
                    à un site existant.
                    Si toutefois, vous avez une question, contactez moi !
                </p>
                <div className='contact-form'>
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type="text" name='name' placeholder='Votre nom' required/>
                            </li>
                            <li className='half'>
                                <input type="email" name='email' placeholder='Votre email' required/>
                            </li>
                            <li>
                                <textarea placeholder='Votre message' name='message' required/>
                            </li>
                            <li>
                                <input name='submit' type='submit' value='Envoyer' className="flat-button"/>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Contact